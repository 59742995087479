import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import AppStoreLogo from '../../components/AppStoreLogo/AppStoreLogo';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';

import * as classes from './aida-mobile.module.scss';

const AidaMobilePage = props => {
    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="aida-mobile-page:pageTitle">AIDA Mobile</Trans>}
            subtitle={<Trans i18nKey="aida-mobile-page:pageSubtitle">Scanning, automatic recognition, tasks and archive
                                                                     directly on your smartphone</Trans>}
            cover="apple-aida-mobile.jpg"
        />
        <div className="container p-0 intro row">
            <div className="col-md-9">
                <Trans i18nKey="aida-mobile-page:pageDescription">
                    <p>Introducing <strong>AIDA Mobile</strong>, the ultimate document management app for Apple devices.
                       Simplify your life with features like one-touch scanning and automatic document recognition—all
                       from the convenience of your smartphone.</p>
                    <p>Our advanced technology offers the <strong>precision of a flatbed scanner</strong>, without the
                       need for dedicated hardware.</p>
                    <p>Stay in the loop with push notifications, tackle tasks effortlessly, and <strong>enjoy the
                                                                                                        flexibility</strong> of
                       switching between web and mobile platforms.</p>
                </Trans>
            </div>
            <div className={clsx('col-md-3', classes.Store)}>
                <AppStoreLogo />
            </div>
        </div>
        <div className={clsx(classes.FeaturesContainer, 'container p-0')}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage src="../../images/illustrations/tclab-aida-20.jpg" alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <Trans i18nKey="aida-mobile-page:feature1">
                                <p>AIDA Mobile <strong>automatically crops the document</strong> from the photograph and
                                   corrects its perspective, <strong>flattening and straightening it</strong>.</p>
                                <p>You can choose to transform the scan thus obtained in black and white, or leave the
                                   original colors. The page format is calculated automatically, or it is adapted to
                                   A4/A3/Letter/Legal standards.</p>
                                <p>Scans in AIDA Mobile are <strong>as accurate as those of a traditional flatbed
                                                                    scanner or a multifunctional</strong>, but they are
                                   now within everyone's reach, without the need for dedicated hardware, and the
                                   printout of the scan has the quality of a photocopy.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <Trans i18nKey="aida-mobile-page:feature2">
                                <p>Field extraction is trained with 1 touch/1 document, like on the web version of AIDA,
                                   but the experience has been <strong>completely redesigned for mobile</strong>, with
                                   dedicated and differentiated applications for smartphones and tablet.</p>
                                <p>Always keep the processed documents under control and choose to <strong>work
                                                                                                           freely</strong> on
                                   the platform you prefer, always having all the tools available.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage src="../../images/illustrations/tclab-aida-21.jpg" alt="AIDA" height={220} />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage src="../../images/illustrations/tclab-aida-10.jpg" alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <Trans i18nKey="aida-mobile-page:feature3">
                                <p>Access the documents processed from the archive, <strong>unlimited for every
                                                                                            plan</strong>, in a
                                   simple way with a <strong>powerful search engine</strong>, share them or simply
                                   browse them.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <Trans i18nKey="aida-mobile-page:feature4">
                                <p>Manage and complete <strong>tasks</strong> related to your documents, from your
                                   smartphone or tablet.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage src="../../images/illustrations/tclab-aida-19.jpg" alt="AIDA" />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage src="../../images/illustrations/tclab-aida-08.jpg" alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <Trans i18nKey="aida-mobile-page:feature5">
                                <p>The <strong>push notifications</strong> always keep you updated on the processed
                                   documents, whether they are automatically recognized or your intervention is needed
                                   to resolve an anomaly.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default AidaMobilePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "aida-mobile-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'aida-mobile-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Platform - AIDA Mobile';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
